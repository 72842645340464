
<template>
    <div><!--payletter--></div>
</template>
<script>
import api from "@/api";
export default {
    data(){
        return {
            orderNo: this.$route.query.orderNo,
        };
    },
    methods: {
        async init() {
            let { onlineUrl, mobileUrl } = await api.plugins.payletter.request.get({ params: { orderNo: this.orderNo } });


            if (this.isMobile()) {
                window.location.href = mobileUrl;
            } else {
                window.location.href = onlineUrl;
            }
        },
        isMobile() {
            return navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("iPhone") > -1;
        },
    },
    mounted(){
        window.payletterComplete = (payload) => {
            window?.parent?.payletterComplete(payload);
            window?.opener?.payletterComplete(payload);
        };
        this.init();
    },
}
</script>
